import { graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'
import { RichText } from 'prismic-reactjs'
import React, { useEffect, useMemo, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { OptionTypeBase } from 'react-select'
import CompanyLogoCard from 'src/components/company-logo-card'
import { parameterize } from 'src/paths'
import { BbvPageProps, PrismicCompaniesProps, PrismicCompanyNode } from 'src/types'
import { sortLabels } from 'src/utils/sort'
import { StringParam, useQueryParam } from 'use-query-params'
import Layout from '../components/layout'
import Select from '../components/select'
import * as styles from './portfolio.module.scss'

export const query = graphql`
  query PortfolioCompaniesQuery {
    allPrismicCompany(sort: { order: DESC, fields: data___invested_date }) {
      nodes {
        uid
        tags
        data {
          logo {
            fixed(width: 233) {
              ...GatsbyPrismicImageFixed_noBase64
            }
            alt
          }
          summary {
            text
          }
          name {
            raw
            text
          }
          title {
            text
          }
          feature
        }
        tags
      }
    }
    prismicPortfolio {
      data {
        title {
          raw
          text
        }
        description {
          raw
        }
        companies_heading {
          text
        }
      }
    }
    prismicSettings {
      ...settings
    }
    prismicLinks {
      ...links
    }
  }
`

const Portfolio = (props: BbvPageProps<PrismicCompaniesProps>) => {
  const [companies, setCompanies] = useState<PrismicCompanyNode[]>([])
  const [selectedTag, setSelectedTag] = useQueryParam('tag', StringParam)

  const portfolio = props.data.prismicPortfolio.data

  useEffect(() => {
    const nodes = props.data.allPrismicCompany.nodes
    const featuredCompanies = nodes.filter((node) => node.data.feature === true)
    const otherCompanies = nodes.filter((node) => node.data.feature !== true)
    setCompanies([...featuredCompanies, ...otherCompanies])
  }, [props.data.allPrismicCompany.nodes])

  const tagCounts = useMemo(() => {
    const counts: Record<string, number> = {}
    companies.forEach((node) =>
      node.tags.forEach((tag) =>
        counts[tag] ? (counts[tag] = counts[tag] + 1) : (counts[tag] = 1)
      )
    )
    return counts
  }, [companies])

  const selectOptions = sortLabels(
    Object.keys(tagCounts).map((tag) => ({
      label: `${tag} (${tagCounts[tag]})`,
      value: tag,
    }))
  )

  const filteredCompanies = selectedTag
    ? companies.filter((node) => node.tags.some((tag) => selectedTag === parameterize(tag)))
    : companies

  const selectedValue = selectOptions.find((o) => parameterize(o.value) === selectedTag)

  return (
    <Layout title={portfolio.title.text} pageData={props.data}>
      <div className="page-container dark-container">
        <Container>
          <RichText render={portfolio.title.raw} />

          <hr />
          <div className={styles.description}>
            <RichText render={portfolio.description.raw} />
          </div>
          <hr />

          <div className={styles.companiesHeader}>
            <Row>
              <Col xs={12} sm={6} md={8} lg={9}>
                <div className={styles.companiesHeading}>{portfolio.companies_heading.text}</div>
              </Col>
              <Col xs={12} sm={6} md={4} lg={3}>
                {selectOptions.length > 0 && (
                  <div className={styles.portfolioFilterSelect}>
                    <Select
                      placeholder="Filter by tags"
                      value={selectedValue}
                      options={selectOptions}
                      onChange={(newSelected: OptionTypeBase) =>
                        setSelectedTag(newSelected ? parameterize(newSelected.value) : undefined)
                      }
                      isClearable={true}
                      dark={true}
                    />
                  </div>
                )}
              </Col>
            </Row>
          </div>
          <Row>
            {filteredCompanies.map((node: PrismicCompanyNode) => (
              <Col xs={12} sm={4} md={3} key={node.uid}>
                <CompanyLogoCard uid={node.uid} image={node.data.logo} small={true} />
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default withPreview(Portfolio)
